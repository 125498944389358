var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-app flex-row align-items-center"},[_c('CContainer',[_c('CRow',{staticClass:"justify-content-center mb-4"},[_c('CCol',{attrs:{"col":"12","md":"4","xl":"2"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require('../../assets/logo.svg')}})])],1),_c('CRow',{staticClass:"justify-content-center"},[_c('CCol',{attrs:{"md":"8","xl":"4"}},[_c('CCardGroup',[_c('CCard',{ref:"loginRef",staticClass:"p-4"},[_c('CCardBody',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('CForm',{attrs:{"role":"form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.doLogin)}}},[_c('h1',[_vm._v("Login")]),_c('p',{staticClass:"text-muted"},[_vm._v("Sign In to your account")]),_c('ValidationProvider',{attrs:{"rules":{ emailInput: true, emailRequired: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('CInput',{attrs:{"type":"email","placeholder":"Email","autocomplete":"username email","isValid":!validated ? null : (invalid || _vm.errorMessage !== '' ? false : true)},scopedSlots:_vm._u([{key:"prepend-content",fn:function(){return [_c('CIcon',{attrs:{"name":"cil-envelope-closed"}})]},proxy:true},(errors[0])?{key:"invalid-feedback",fn:function(){return [_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]},proxy:true}:null],null,true),model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{ passwordRequired: true },"field":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('CInput',{attrs:{"placeholder":"Password","type":"password","autocomplete":"current-password","isValid":!validated ? null : (invalid || _vm.errorMessage !== '' ? false : true)},scopedSlots:_vm._u([{key:"prepend-content",fn:function(){return [_c('CIcon',{attrs:{"name":"cil-lock-locked"}})]},proxy:true},(errors[0])?{key:"invalid-feedback",fn:function(){return [_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]},proxy:true}:null],null,true),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),(_vm.errorMessage !== '')?_c('div',{staticClass:"text-danger mb-2"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()]}}],null,true)}),_c('CRow',[_c('CCol',{staticClass:"text-center text-md-left",attrs:{"col":"12","md":"6"}},[_c('CButton',{staticClass:"px-4",attrs:{"color":"primary","type":"submit"}},[_vm._v("Login")])],1)],1)],1)]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }