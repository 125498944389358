<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center mb-4">
        <CCol col="12" md="4" xl="2">
          <img :src="require('../../assets/logo.svg')" class="img-fluid"/>
        </CCol>
      </CRow>
      <CRow class="justify-content-center">
        <CCol md="8" xl="4">
          <CCardGroup>
            <CCard class="p-4" ref="loginRef">
              <CCardBody>
                <ValidationObserver v-slot="{handleSubmit}">
                  <CForm role="form" @submit.prevent="handleSubmit(doLogin)">
                    <h1>Login</h1>
                    <p class="text-muted">Sign In to your account</p>
                    <ValidationProvider :rules="{ emailInput: true, emailRequired: true }"
                                        v-slot="{errors, valid, invalid, validated}">
                      <CInput
                          type="email"
                          placeholder="Email"
                          autocomplete="username email"
                          v-model="email"
                          :isValid="!validated ? null : (invalid || errorMessage !== '' ? false : true)"
                      >
                        <template #prepend-content>
                          <CIcon name="cil-envelope-closed"/>
                        </template>
                        <template #invalid-feedback v-if="errors[0]">
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </template>
                      </CInput>
                    </ValidationProvider>
                    <ValidationProvider :rules="{ passwordRequired: true }" field="email"
                                        v-slot="{errors, valid, invalid, validated}">
                      <CInput
                          placeholder="Password"
                          type="password"
                          autocomplete="current-password"
                          v-model="password"
                          :isValid="!validated ? null : (invalid || errorMessage !== '' ? false : true)"
                      >
                        <template #prepend-content>
                          <CIcon name="cil-lock-locked"/>
                        </template>

                        <template #invalid-feedback v-if="errors[0]">
                          <div class="invalid-feedback">{{ errors[0] }}</div>
                        </template>
                      </CInput>
                      <div v-if="errorMessage !== ''" class="text-danger mb-2">
                        {{ errorMessage }}
                      </div>
                    </ValidationProvider>
                    <CRow>
                      <CCol col="12" md="6" class="text-center text-md-left">
                        <CButton color="primary" class="px-4" type="submit">Login</CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </ValidationObserver>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import {ValidationProvider, ValidationObserver, extend} from "vee-validate";
import {email, required} from 'vee-validate/dist/rules';

export default {
  name: 'Login',

  components: {
    ValidationProvider,
    ValidationObserver
  },

  data() {
    return {
      email: '',
      password: '',
      errorMessage: '',
    };
  },

  methods: {
    doLogin() {
      this.errorMessage = '';

      let loader = this.$loading.show({
        // Optional parameters
        container: this.$refs.loginRef,
        canCancel: false
      });
      this.$store.dispatch('auth/prefill')
          .then(() => {
            this.$store.dispatch('auth/doLogin', {
              email: this.email,
              password: this.password
            })
                .then(res => {
                  let {success} = res.data;

                  if (!success) {
                    this.errorMessage = 'Email or password is wrong';
                  } else {
                    this.$router.push({name: 'Dashboard'});
                  }
                })
                .catch(() => {
                  this.errorMessage = 'Email or password is wrong';
                })
                .finally(() => {
                  loader.hide();
                });
          });
    }
  },

  mounted() {
    extend('emailInput', {
      ...email,
      message: () => 'Email format is wrong',
    });

    extend('emailRequired', {
      ...required,
      message: () => "Email is required"
    });

    extend('passwordRequired', {
      ...required,
      message: () => 'Password is required',
    });

    let loader = this.$loading.show({
      // Optional parameters
      canCancel: false
    });

    // Check authenticated and redirect

    this.$store.dispatch('auth/checkAuthenticated')
        .then(res => {
          let {success} = res.data;

          loader.hide();

          if (success) {
            this.$router.push({name: 'Dashboard'});
          }
        });
  }
}
</script>
